(() => {
    const randomBetween = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const hexToRgb = hex => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    /**
     * Logo
     */

    const presets = [
        ['#e46e6e', '#91a2ed'],
        ['#886fd3', '#4cd2c5'],
        ['#6fd372', '#d66363'],
        ['#989898', '#d66363'],
        ['#8a76f6', '#363636'],
        ['#d3ab6f', '#6381d6'],
    ];

    const logo = document.querySelector('#logo');
    const socials = document.querySelector('socials');

    let timer = 0;

    setTimeout(() => {
        logo.classList.add('showOutline');
    }, timer);

    timer += 1200;

    setTimeout(() => {
        logo.classList.add('hideOutline');
    }, timer);

    timer += 0;

    setTimeout(() => {
        logo.classList.add('shades');
    }, timer);

    timer += 0;

    setTimeout(() => {
        logo.classList.add('parts');
    }, timer);



    let hueM, hueL;

    let randomColors = () => {
        // Have two seperate timers that change the shape colors
        hueM = `hsl(${randomBetween(0, 360)}deg 63% 80%)`;
        // let hueL = randomBetween(0, 360);
        hueL = hueM;
    };

    let presetColors = () => {
        // get random preset;
        let preset = presets[randomBetween(0, presets.length - 1)];


        if (randomBetween(1, 2) % 2 == 1) {
            hueM = preset[0];
            hueL = preset[1];
        } else {
            hueM = preset[1];
            hueL = preset[0];
        }
    };

    // randomColors();
    presetColors();

    logo.style.setProperty('--colorShapeM', hueM);
    logo.style.setProperty('--colorShapeL', hueL);

    const setMLoop = () => {
        setInterval(() => {
            hueM = (hueM + 1 > 360 ? 0 : hueM + 1);
            logo.style.setProperty('--colorShapeM', `hsl(${hueM}deg 63% 77%)`);
        }, 100);
    }

    const setLLoop = () => {
        setInterval(() => {
            hueL = (hueL + 1 > 360 ? 0 : hueL + 1);
            logo.style.setProperty('--colorShapeL', `hsl(${hueL}deg 63% 77%)`);
        }, 120);
    };

    // setMLoop();
    // setLLoop();

    /**
     * Background
     */

    const getDistance = (p1, p2) => {
        return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }

    class Cirlce {
        constructor(pos, rad, color) {
            this.pos = pos;
            this.rad = rad;
            this.color = color;
        }

        draw(canvas) {
            const ctx = canvas.getContext('2d');
            ctx.beginPath();
            ctx.arc(this.pos.x, this.pos.y, this.rad, 0, 2 * Math.PI, false);
            ctx.fillStyle = this.color;
            ctx.fill();
        }
    }

    const initBackground = () => {
        let width = window.innerWidth;
        let height = window.innerHeight;

        const canvas = document.getElementById('background');
        const ctx = canvas.getContext('2d');

        canvas.height = height;
        canvas.width = width;

        const points = [];
        const pointDensity = window.innerWidth / 110;

        for (let x = 0; x < width; x += width / pointDensity) {
            for (let y = 0; y < height; y += height / pointDensity) {
                let pointX = x + Math.random() * width / pointDensity;
                let pointY = y + Math.random() * height / pointDensity;

                points.push({
                    x: pointX,
                    y: pointY
                });
            }
        }

        points.forEach((point, index) => {
            let rgbDot = hexToRgb(hueL);
            let cirlce = new Cirlce(point, 2 + Math.random() * 1.5, `rgba(${rgbDot.r}, ${rgbDot.g}, ${rgbDot.b}, .3)`);

            // Calculate distance between points
            let distances = [];
            points.forEach(otherPoint => {
                if (point !== otherPoint) {
                    distances.push({
                        point: otherPoint,
                        distance: getDistance(point, otherPoint),
                    });
                }
            });

            distances = distances.sort((a, b) => a.distance - b.distance).slice(0, 5);
            points[index].distances = distances;


            // Start drawing lines between dots
            point.distances.forEach(otherPoint => {
                let rgbLine = hexToRgb(hueM);

                ctx.beginPath();
                ctx.moveTo(point.x, point.y);
                ctx.lineTo(otherPoint.point.x, otherPoint.point.y);
                ctx.strokeStyle = `rgba(${rgbLine.r}, ${rgbLine.g}, ${rgbLine.b}, ${otherPoint.distance / 100000})`;
                ctx.stroke();

                console.log(otherPoint.distance / 100000);
            });

            cirlce.draw(canvas);
        });

        // console.log(points);
    };

    initBackground();

    window.onresize = () => {
        const canvas = document.getElementById('background');
        canvas.height = window.innerHeight;
        canvas.width = window.innerWidth;

        initBackground();
    }

})();